/**
 * Onbarding page (collects bank account information)
 * For right now it's just a button that
 * redirects to stripe-express.
 */

import React from 'react'
import styled from '@emotion/styled'

import KEYS from 'constants/keys'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'

import './style.scss'
import { button } from 'utils/styles'
import stripe_img from '../../../static/img/stripe/stripe_integration.png'

const ConnectBtn = styled('button')`
  display: flex;
  flex: 0.8;
  width: 70% !important;
  ${button.purple};
  ${button.big};
  @media all and (max-width: 500px) {
    width: 100% !important;
  }
`

class OnboardingPage extends React.Component {
  // Redirects to the appropriate stripe express url
  redirectToExpress = () => {
    const { email, uid, phoneNumber = undefined } = this.props.currentUser

    const params = {
      //redirect_uri: `${window.location.href}/redirect`, // hardcoded in stripe dashboard
      client_id: KEYS.STRIPE.CONNECT_ID,
      // state: <CSRF_TOKEN>, // TODO: Generate and validate CSRF token
      // TODO: Prefill User's name, email, phone number, etc...
    }
    const p = new URLSearchParams(params)
    const href = `https://connect.stripe.com/express/oauth/authorize?${p.toString()}`
    window.location.href = href
  }

  render() {
    return (
      <div className="base">
        <div className="content-wrap">
          <section className="form-title page-title">
            <h2>Payments with Mellow</h2>
          </section>
          <section className="onboarding-page-container">
            <div className="onboarding-page-subcontainer">
              <p className="main-txt">
                To recieve payment for your rentals, you need to connect a bank
                account or debit card. All payments are processed securely by
                our partner Stripe.
              </p>
              <div className="stripe-section">
                <img
                  src={stripe_img}
                  className="stripe-integration"
                  height="90"
                />
                <div className="feature-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="check"
                    className="payments-check"
                    role="img"
                    viewBox="0 0 512 512"
                    data-ember-extension="1"
                  >
                    <path
                      fill="currentColor"
                      d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                    />
                  </svg>
                  <p className="feature-title">Secure</p>
                  <p className="hint-text">
                    Transfer of your private information is encrypted end-to-end
                  </p>
                </div>
                <div className="feature-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="check"
                    className="payments-check"
                    role="img"
                    viewBox="0 0 512 512"
                    data-ember-extension="1"
                  >
                    <path
                      fill="currentColor"
                      d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                    />
                  </svg>
                  <p className="feature-title">Private</p>
                  <p className="hint-text">
                    Your credentials will never be made accessible to Mellow
                  </p>
                </div>
              </div>
              <ConnectBtn onClick={this.redirectToExpress}>
                Connect with Stripe
              </ConnectBtn>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default props => (
  <Layout {...props} displayFooter={false} showBeacon={true}>
    <SEO title="Payments with Mellow" />
    <OnboardingPage {...props} />
  </Layout>
)
